export enum MoneyRequestKindEnum {
    DEPOSIT = 1,
    WITHDRAWAL = 2,
}

export const MONEYREQUEST_KIND_LABELS = {
    [MoneyRequestKindEnum.DEPOSIT]: "충전",
    [MoneyRequestKindEnum.WITHDRAWAL]: "환전",
}


export enum MoneyRequestStatusEnum {
    REQUESTED = 1,
    PENDING = 2,
    ACCEPTED = 3,
    REJECTED = 4,
    CANCELED = 5,
}

export const MONEYREQUEST_STATUS_LABELS = {
    [MoneyRequestStatusEnum.REQUESTED]: "신청",
    [MoneyRequestStatusEnum.PENDING]: "대기",
    [MoneyRequestStatusEnum.ACCEPTED]: "승인",
    [MoneyRequestStatusEnum.REJECTED]: "거절",
    [MoneyRequestStatusEnum.CANCELED]: "승인취소",
}
