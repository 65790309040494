import { LanguageContext } from '@/contexts/languageContext';
import { useContext } from 'react';
import Swal from 'sweetalert2';
import { getCookie } from '@/utils/cookie';

type iconType = "success" | "warning" | "error"

export default async function customAlert(message: string, icon: iconType, onChecked?: Function) {
    await Swal.fire({
        text: message,
        icon: icon,
    });
    if (onChecked) {
        onChecked();
    }
}

