import { AnonymousWhoamiType, WhoamiType } from "@/types/user";
import { useCallback, useEffect, useState } from "react";
import api from "@/utils/api";

const anonymousUser: AnonymousWhoamiType = { is_authenticated: false, id: undefined }

export default function useUser() {
    const [user, setUser] = useState<WhoamiType>(anonymousUser)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api('/api/auth/whoami/')
            .then(res => res.json())
            .then(data => {
                setUser(data)
                setLoading(false)
            })
            .catch(() => setLoading(false))
    }, [])

    const login = useCallback((user: WhoamiType) => {
        setUser(user)
    }, [])

    const logout = useCallback(() => {
        api("/api/auth/logout/", { method: "POST" }).then(() => setUser(anonymousUser))
    }, [])

    return { user, loading, setUser, login, logout }
}
