import getCookie from "./getCookie"
import { toast } from "react-toastify"
import Swal from "sweetalert2"


export default async function api(input: RequestInfo | URL, init?: RequestInit, feedback?: boolean, ...args: any[]) {
    const method = init?.method || "GET";

    let promise: Promise<Response>

    if (["POST", "PATCH", "PUT", "DELETE"].includes(method)) {
        let csrftoken = getCookie("csrftoken");
        if (!csrftoken) {
            await fetch("/api/csrf-token");
            csrftoken = getCookie("csrftoken");
        }
        promise = fetch(input, init && { ...init, headers: { ...init.headers, "X-CSRFToken": csrftoken! } }).then(async (res) => {
            if (res.ok) {
                return res;
            }
            else {
                throw res;
            }
        });
    }
    else {
        promise = fetch(input, init && { ...init, headers: { ...init.headers } }).then(async (res) => {
            if (res.ok) {
                return res;
            } else {
                throw res;
            }
        });
    }


    if (feedback) {
        Feedback(promise)
    }

    return promise
}

function Feedback(promise: any) {
    const swal: any = {
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
    }

    const dialog = document.querySelector("dialog");

    if (dialog && dialog.open) {
        swal["target"] = "dialog"
    }

    return (
        promise
            .then(() =>
                Swal.fire({ ...swal, icon: "success", title: "성공" }))
            .catch(() =>
                Swal.fire({ ...swal, icon: "warning", title: "실패" }))

    )
}

