import { ReactNode } from "react";
import Image from "next/image";

export default function Mainternance({ children }: { children?: ReactNode }) {
    return (
        <div className="site-inspection">
            <div className="context-inner">
                <header>
                    <Image src={'/logo.png'} width={225} height={65} alt={'logo'} />
                </header>
                <main className="context-body">
                    <div className="scroll-inner">
                        <div className="content">{children}</div>
                    </div>
                </main>
            </div>
        </div>
    )
}
