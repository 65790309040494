"use client"

import ToastMessage from "@/components/Primitive/Toast/ToastMessage";
import useUser from "@/hooks/useUser";
import { SocketCloseCode } from "@/types/socket";
import { PermissionsType, WalletType, WhoamiType } from "@/types/user";
import customAlert from "@/utils/customAlert";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { SiteContext } from "./siteContext";
import api from "@/utils/api";

import { MoneyRequestStatusEnum, MONEYREQUEST_STATUS_LABELS, MoneyRequestKindEnum } from "@/types/wallet/moneyRequest";
import { QuestionKindEnum, QUESTION_KIND_LABELS } from "@/types/center";

interface MessageListenerType {
    (message: any): null
}

const AuthContext = createContext<{
    user: WhoamiType,
    loading: boolean,
    login?: any,
    logout?: any,
    setUser?: any,
    socketLoading: boolean,
    addMessageListener?: any,
    removeMessageListener?: any
    unreadMessageCount: number
    closeCode?: number,
    handleExchange?: any,
    handleExchangeComp?: any,
    ErroHandler?: any,
}>({
    user: { is_authenticated: false, id: undefined },
    loading: true,
    socketLoading: true,
    unreadMessageCount: 0,
});

const PermissionContext = createContext<{ permissions?: any[] }>({})

const WalletContext = createContext<WalletType>({ money: 0, point: 0, comp: 0 })

const ListContext = createContext<{ type: string, data: any }>({ type: "", data: undefined })

function AuthProvider({ children }: { children: any }) {
    const { SetMaintenance } = useContext(SiteContext)
    const { user, loading, setUser, login, logout } = useUser();
    const [closeCode, setCloseCode] = useState<number>();

    const [wallet, setWallet] = useState<WalletType>({ money: 0, point: 0, comp: 0 })
    const [permissions, setPermissions] = useState<PermissionsType>()
    const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0)
    const [messageData, setMessageData] = useState<{ type: string, data: any }>({ type: "", data: undefined })

    // 소켓
    const [socketLoading, setSocketLoading] = useState<boolean>(true)
    const socketRef = useRef<WebSocket | null>(null);
    const retryCountRef = useRef<number>(0);

    // 브로드캐스트
    const messageListenerListRef = useRef<MessageListenerType[]>([]);
    const authBroadCastChannelRef = useRef<BroadcastChannel | null>(null);

    function addMessageListener(callback: MessageListenerType) {
        messageListenerListRef.current.push(callback)
    }

    function removeMessageListener(callback: MessageListenerType) {
        messageListenerListRef.current = messageListenerListRef.current.filter(item => item !== callback)
    }

    // 재화
    function handleExchange() {
        api("/api/auth/exchange/", { method: "POST" })
            .then(() => customAlert('포인트 전환이 완료되었습니다.', 'success'))
            .catch(() => customAlert('오류가 발생했습니다.', 'error'))
    }

    function handleExchangeComp() {
        api("/api/auth/exchange_comp/", { method: "POST" })
            .then(() => customAlert('콤프 전환이 완료되었습니다.', 'success'))
            .catch((err: Response) => ErroHandler(err))
    }

    async function ErroHandler(err: Response) {
        const message = await err.json()
        switch (err.status) {
            default:
                customAlert('오류가 발생했습니다.', 'warning')
                break;
            case 400:
                customAlert(message, 'warning')
                break;
        }
    }


    useEffect(() => {
        // const socketURL = process.env.NODE_ENV === 'development' ? `wss://user1.foronlinetest.com/ws/user` : `wss://${window.location.hostname}/ws/user`
        const socketURL = `${window.location.protocol === "https:" ? 'wss' : 'ws'}://${window.location.host}/ws/user`

        if (loading) {
            return
        }

        if (!user.is_authenticated) {
            return
        } else {
            setWallet({ money: user.money, point: user.point, comp: user.comp })
        }

        function connect() {
            const userSocket = new WebSocket(socketURL)
            userSocket.onopen = handleOpen
            userSocket.onclose = handleClose
            userSocket.onmessage = handleMessage
            return userSocket
        }

        function handleOpen() {
            retryCountRef.current = 0
            setSocketLoading(false)
        }

        function handleUserUpdate(message: any) {
            switch (message.type) {
                case "question":
                case "moneyrequest":
                    setMessageData(message)
                    toastMessageProgress(message.type, message)
                    break;

                case "wallet":
                    setWallet({ money: message.money, point: message.point, comp: message.comp })
                    break

                case "permissions":
                    setPermissions(message.permissions)
                    break

                case "kick":
                    setUser({ is_authenticated: false, id: undefined })
                    break

                case "unread_message_count":
                    setUnreadMessageCount(message.count)
                    break

                case "bet":
                    break
            }
        }

        function handleMessage(event: MessageEvent) {
            const message = JSON.parse(event.data)

            handleUserUpdate(message)
            for (const handler of messageListenerListRef.current) {
                handler(message)
            }
        }

        function handleClose(event: any) {
            switch (event.code) {
                case 1000:
                    return
                case SocketCloseCode.NOT_AUTHENTICATED:
                    setSocketLoading(false)
                    setCloseCode(SocketCloseCode.NOT_AUTHENTICATED)
                    toast('비로그인')
                    return
                case SocketCloseCode.SESSION_KICK:
                    logout()
                    setSocketLoading(false)
                    setCloseCode(SocketCloseCode.SESSION_KICK)
                    toast('세션 강제 종료')
                    return
                case SocketCloseCode.KICK:
                    logout()
                    setSocketLoading(false)
                    setCloseCode(SocketCloseCode.KICK)
                    toast('전체 강제 종료')
                    return
                case SocketCloseCode.LOGIN_ELSEWHERE:
                    logout()
                    setSocketLoading(false)
                    setCloseCode(SocketCloseCode.LOGIN_ELSEWHERE)
                    customAlert(`다른 곳에서 중복 로그인 되었습니다.`, 'warning')
                    return
                case SocketCloseCode.UNDER_MAINTENANCE:
                    logout()
                    setSocketLoading(false)
                    setCloseCode(SocketCloseCode.UNDER_MAINTENANCE)
                    SetMaintenance()
                    return
            }

            setTimeout(() => {
                const RetryUserSocket = connect()
                retryCountRef.current += 1
                socketRef.current?.close()
                socketRef.current = RetryUserSocket
            }, 1000)
        }

        const UserSocket = connect()
        socketRef.current = UserSocket

        return () => { socketRef.current?.close(1000) }
    }, [user.is_authenticated, user.id, loading, setUser, logout])


    return (
        <AuthContext.Provider value={{
            user, loading, setUser, login, logout, socketLoading, addMessageListener, removeMessageListener, unreadMessageCount, closeCode,
            handleExchange, handleExchangeComp, ErroHandler
        }}>
            <PermissionContext.Provider value={{ permissions }}>
                <WalletContext.Provider value={{ ...wallet }}>
                    <ListContext.Provider value={{ ...messageData }}>
                        {children}
                    </ListContext.Provider>
                </WalletContext.Provider>
            </PermissionContext.Provider>
        </AuthContext.Provider>
    )
}


function toastMessageProgress(type: string, message: any) {
    type statusType =
        MoneyRequestStatusEnum.REQUESTED |
        MoneyRequestStatusEnum.PENDING |
        MoneyRequestStatusEnum.ACCEPTED |
        MoneyRequestStatusEnum.REJECTED |
        MoneyRequestStatusEnum.CANCELED;

    const messageType = type;
    const data = message.data;
    const status: statusType = data.status;

    const sendToastMessage = (title: string, message: string, id: number) => {
        toast(<ToastMessage title={title} message={message} onClick={() => location.href = `/mypage/question?id=${id}`} />)
    }

    if (messageType === "moneyrequest") {
        const id = data.id;
        const title = `${data.kind === MoneyRequestKindEnum.DEPOSIT ? "충전" : "환전"}신청 (${data.amount.toLocaleString()})`;
        const mesage = `${MONEYREQUEST_STATUS_LABELS[status]} 되었습니다.`;

        return sendToastMessage(title, mesage, id)
    }

    if (messageType === "question" && data.kind === QuestionKindEnum.NORMAL) {
        const id = data.id;
        const title = QUESTION_KIND_LABELS[QuestionKindEnum.NORMAL] + " 문의";
        const message = "1:1 문의 답변이 도착 했습니다.";

        return sendToastMessage(title, message, id)
    }

    if (messageType === "question" && data.kind === QuestionKindEnum.DEPOSIT) {
        const id = data.id;
        const title = QUESTION_KIND_LABELS[QuestionKindEnum.DEPOSIT] + " 문의";
        const message = "계좌 문의 답변이 도착 했습니다.";

        return sendToastMessage(title, message, id)
    }
}

export { AuthContext, AuthProvider, PermissionContext, WalletContext, ListContext };