'use client'

import { SiteConfigType } from "@/types/siteConfig";
import api from "@/utils/api";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { AuthContext } from "./authContext";
import Mainternance from "@/app/(root)/Maintenance";

interface SiteContextType {
    config: SiteConfigType | undefined
    SetMaintenance: () => void
}

const SiteContext = createContext<SiteContextType>({ config: undefined, SetMaintenance: () => { } })

function SiteProvider({ children }: { children: React.ReactNode }) {
    const [config, setConfig] = useState<SiteConfigType>()
    const [maintenance, setMaintenance] = useState<boolean>(false)

    const SetMaintenance = () => setMaintenance(true);

    useEffect(() => {
        api('/api/config/configs/')
            .then(res => res.json().then(data => setConfig(data)))
    }, [])

    useEffect(() => {
        config && config.is_under_maintenance && SetMaintenance()
    }, [config])

    return (
        <SiteContext.Provider value={{ config, SetMaintenance }}>
            {
                maintenance
                    ? <Mainternance>{config?.maintenance_reason}</Mainternance>
                    : children
            }
        </SiteContext.Provider>
    )
}

export { SiteContext, SiteProvider };
